<template>
    <div class="doneOrderList">

        <scrollview @onRefresh="onRefresh" @onReload="onReload" :height="scrollHeight">
        <div class="log-card" v-for="(item, index) in list" :key="index">
            <div class="log-card-top">
                <div>
                    <img style="width:18px;vertical-align:middle;" :src="require('@STATIC/image/icons/log-money.png')" />
                    佣金提现
                </div>
                <div>￥{{item.money}}</div>
            </div>
            <div class="log-card-line"></div>
            <div class="log-card-desc">申请时间：{{item.create_time}}</div>
            <div class="log-card-desc">处理状态：<span :style="{color:getColor(item.status)}">{{item.status_text}}</span></div>
        </div>
        </scrollview>



    </div>
</template>

<script>
    export default {
        name: "with-drawal-log",
        created() {

            this.doWithDrawLog(this.params)
        },
        mounted() {
          document.title = '配送员提现记录'
            this.scrollHeight = window.innerHeight
        },

        data () {
            return {
                params: {
                    page: 1,
                    page_size: 15,
                },
                list: [],
                scrollHeight: 0,
            }
        },
        methods: {
            getColor(tip) {
                if (tip==0){
                    return "#F46A17"
                }else if (tip==1){
                    return "#979797"
                }else if (tip==2){
                    return "#E40000"
                }

            },
            onRefresh(done) {
                this.list = [];
                this.params.page = 1;
                this.doWithDrawLog(this.params).finally(() => {
                    done();
                });
            },
            /**
             * step 当前加载结束
             * over 没有更多数据了
             */
            onReload(step, over) {

                this.params.page += 1;
                this.doWithDrawLog(this.params).finally(() => {
                    if (this.list.length < this.params.page * this.params.page_size) {
                        over();
                    } else {
                        step();
                    }
                });
            },
            //可提现金额
            async doWithDrawLog(params){
                try {
                    let query = await this.$api.community.courier.doWithDrawLog(params)
                    console.log(query)
                    if (query.data.length == 0) {
                        return;
                    }
                    this.list = [...this.list, ...query.data];
                } catch (error) {
                    console.log('dataInfo error', error)
                }


            },
        }
    }
</script>

<style scoped lang="scss">
    @include b(doneOrderList) {
        width: 100vw;
        height: 100vh;
        background: #F8F8F8;
        display: flex;
        flex-direction: column;
        .log-card{
            background: #FFFFFF;
            margin-bottom: 8px;
            padding-bottom:8px;
            width: 100%;
            border: 0px solid red;
            .log-card-top {
                height: 41px;
                line-height: 41px;
                display: flex;
                justify-content: space-between;
                width: 345px;
                margin: auto;

                font-size: 14px;
                font-family: PingFangSC-Medium, PingFang SC;
                font-weight: 500;
                color: #303030;

                div:nth-child(2){
                    line-height: 41px;
                    font-size: 12px;
                    color: #E40000;
                }

            }

            .log-card-line{
                width: 345px;
                height: 1px;
                background: #F2F2F2;
                margin: auto;
                margin-bottom:8px;
            }

            .log-card-desc{
                width: 345px;
                margin: auto;
                height: 22px;
                font-size: 11px;
                font-family: PingFangSC-Medium, PingFang SC;
                font-weight: 500;
                color: #979797;
                line-height: 22px;
                border: 0px solid red;
            }
        }
    }

</style>